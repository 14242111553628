import { getStreams } from './State';

//import { sleep } from './util';

export async function search(query, state) {
  const streams = getStreams(state);

  const q = query.toLocaleLowerCase();

  return streams.filter(t => {
    
    const fields = [t.title, t.description].map(f => f?f.toLocaleLowerCase():'');
    return fields.some(f => f.indexOf(q) >= 0);
  });
}