import React,{ useContext, useCallback, useEffect } from 'react';
import {
    IonLabel,
    IonList,
    IonItem,
  } from '@ionic/react';

import { AppContext,
    addStream,
    playStream,
    setPlayList,
}from '../State';

import { decodeJWT } from '../util';
import './EpgList.css';

const EpgList = (props) =>{
  const { dispatch } = useContext(AppContext);
  const payloadJWT = decodeJWT(localStorage.getItem('token'));
  const doPlay = useCallback((stream,playList) => {
      dispatch(addStream(stream));
      dispatch(playStream(stream));
      dispatch(setPlayList(playList));
  },[dispatch]);

  function createStream(eventEPG) {
    /* Show console debug information */
    if (payloadJWT.isAdmin){
      const urlDownload = `${props.stream.serverURL}/${props.stream.type==='DVR'?props.stream.parent:props.stream.name}/archive-${eventEPG.start}-${eventEPG.duration}.mp4`;
      console.log(eventEPG.title);
      console.log("%cURL DOWNLOAD:", "color: #00A7F7; font-weight: 700;", urlDownload);
    }

    return ({
      //url:`${props.stream.serverURL}/${props.stream.type==='DVR'?props.stream.parent:props.stream.name}/archive-${eventEPG.spa.start}-${eventEPG.spa.duration}.m3u8`,
      url:`${props.stream.serverURL}/${props.stream.type==='DVR'?props.stream.parent:props.stream.name}/video-${eventEPG.start}-${eventEPG.duration}.m3u8`,
      enabled:true,
      serverURL:props.stream.serverURL,
      category:'DVR',
      name:eventEPG.title+' '+eventEPG.dayOfweek,
      parent:props.stream.type==='DVR'?props.stream.parent:props.stream.name,
      description:eventEPG.description,
      title: eventEPG.title,
      epgId: props.stream.epgId,
      type:'DVR'
    });
  }

  function handleClick(eventEPG){
    //https://iptv.wifibytes.com/la1HD/archive-1610307000-1000.m3u8

    let stream = createStream(eventEPG);
    //alert(stream.url);
    //alert(stream.url);
    doPlay(stream,[stream]);
  }
  function seconds_since_epoch(d){
    return Math.floor( d / 1000 );
  }
  function getClassTime(eventEPG){
    let now = new Date()
    if (seconds_since_epoch(now) >= eventEPG.start) {
      return "past"
    }else{
      return "future"
    }
  }
  /* Generate a hashcode from string */
  function hashCode(word) {
    var hash = 0, i, chr;

    if (!word) return hash;
    for (i = 0; i < word.length; i++) {
      chr   = word.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };
  /**
   * genre could be: 'Cine','Series', 'Entretenimiento', 'Noticias' ....
   * We get a color (random but always the same) depending on genre
   * @param {*} genre 
   */
  function getClassGenre(genre){
    let colors = ['primary','secondary','tertiary','quaternary','quinary','senary'];
    let code = Math.abs(hashCode(genre));
    return colors[code % colors.length];
  }
  /** Used to sort an array of objects by start property */
  function compare( a, b ) {
    if ( a.start < b.start ){
      return -1;
    }
    if ( a.start > b.start ){
      return 1;
    }
    return 0;
  }
  // Scroll to left EPG days
  useEffect(() => {
    // code to run on component mount
    let segment = document.querySelector("ion-segment");
    //alert('arre gatet');
    if (segment) {
      segment.scrollLeft = segment.scrollWidth;
      //alert('arre gat');
    }
  },[]);

  return (
      <IonList className="epgList" name={props.dayOfWeek} style={ (props.dayOfWeek !== props.activeSegment)?{display:'none'}:{}}>
      {props.events.sort(compare).map((eventEPG,index) => (
        <IonItem className={getClassTime(eventEPG)+" "+getClassGenre(eventEPG.genre) + " " + 'epgItem'} key={index}
          onClick={
            () => getClassTime(eventEPG)==='past'?handleClick(eventEPG):''
            }
            >
              <IonLabel secondary className={getClassTime(eventEPG)+" "+getClassGenre(eventEPG.genre)}>
                  <p><b>{eventEPG.textTime}</b></p>
                  <p>{eventEPG.title} {eventEPG.description} - <b>{eventEPG.genre}</b></p>
              </IonLabel>
        </IonItem>
      ))}
    </IonList>
  )
}

export default EpgList;