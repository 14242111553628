import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
    AppContext, getUserEmail
  } 
  from '../State';
import urls from '../urls';

const PrivateRoute = ({component: Component, ...rest}) => {
    const { state, dispatch } = useContext(AppContext);   
    const userEmail = getUserEmail(state);
    
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /LOGIN page
        <Route {...rest} render={props => (
            userEmail ?
                <Component {...props} />
            : <Redirect to={urls.LOGIN} />
        )} />
       
    );
};
 /* <Route path={urls.APP_DVR+"/:streamName"} render={({match}) => (
                <DVR url={`${DVR_SERVER}/${match.params.streamName}/embed.html?dvr=true`} title={match.params.streamName}/>
          )} /> */
export default PrivateRoute;