import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonList,
  IonItem,
  //IonLabel,
  IonInput,
  IonButton,
  IonPage,
  IonButtons,
  IonBackButton,
  IonAlert,
} from "@ionic/react";

import { Link } from 'react-router-dom';

import { resetPassword } from '../auth';

import './Form.css';

import './ResetPassword.css';

import {ISP_NAME, ISP_WEB} from '../config'

export const ResetPassword = ({ history }) => {
  const [showAlertEmailSent, setShowAlertEmailSent] = useState(false);
  //const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [ email, setEmail ] = useState('');
  const [ , setFormErrors ] = useState(null);
  const { t } = useTranslation();

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      const res = await resetPassword(email);

      if (res.success) {
         //setEmailHasBeenSent(true);
         setShowAlertEmailSent(true)
      }
      /*else{
         setEmailHasBeenSent(false);
      }*/

      //setEmail('');
    } catch (e) {
      setFormErrors(e.code);
    }
  }

  /*if (emailHasBeenSent) {
    return <Redirect push to="/login" />
  }*/
  return (
  <IonPage>
    <IonContent className="form">
      <IonAlert
          isOpen={showAlertEmailSent}
          onDidDismiss={() =>history.push('/login', { direction: 'forward' }) }
          cssClass='my-custom-class'
          header={t('resetPassword.alertTitle')+" "+email}
          subHeader={t('resetPassword.action')}
          message={t('resetPassword.instructions')}
          buttons={['OK']}
      />
      <div className='wrapper'>
				<div className='d-flex-center'>
          <IonGrid>
						<IonRow justify-content >
							<IonCol style={{textAlign:'center'}}><img src={"../assets/logo-"+ISP_NAME+".svg"} className="login-logo" alt="Agotelecom webpage" /></IonCol>
						</IonRow>
					</IonGrid>
          <form onSubmit={e => {handleSubmit(e)}} action="post" className='form-box'>
            <p className='text-style'>{t('resetPassword.instructions')}</p>
            <IonList>
              <IonItem>
                {/* <IonLabel position={'fixed'}>Email</IonLabel> */}
                <IonInput type="email" required placeholder="Email" value={email} onInput={e => setEmail(e.currentTarget.value)} />
              </IonItem>

              <IonButton expand="block" type="submit">{t('resetPassword.submit')}</IonButton>

            </IonList>
          </form>

          <div className="below-form">
            <Link to='/login'>{t('resetPassword.comeback')}</Link>
          </div>
        </div>
      </div>
    </IonContent>
  </IonPage>
  );
}

export default ResetPassword;