const URLS = {
  APP_HOME: '/app/browse',
  APP_SEARCH: '/app/search',
  APP_USER: '/app/user',
  APP_INFO: '/app/info', 
  APP_DVR: '/app/DVR', 
  LOGIN: '/login',
  SIGNUP: '/signup',
  RESET_PASSWORD: '/reset-password',  
};

export default URLS;
