import { AUTH_SERVER } from './config';
// These functions are where we make API
// calls to our auth system of choice for login, signup,
// and password reset requests
export const login = async (email, password) => {   
  if (!email || !password){
    return null;
  }else{    
    try{                
        const response=await fetch(AUTH_SERVER+'/public/login', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },        
          body: JSON.stringify({email:email,password:password})        
        }).then((res) => {
          // Your response to manipulate          
          return res;       
        }).catch((error) => {
          // Your error is here!
          return {msg:'Authentication server not reachable '+error }        
        });

        const user = await response.json();

        if (user.token) {        
            return user;          
        }else{
            return {msg:'Invalid Credentials'};
        }
    }catch(e){
        return  {msg:'Authentication server not reachable '+e};      
    }
  }
}

/*export const signup = async (email, password) => {
  // Just to simulate network request
  await sleep(2000);
  // Return a fake user
  return {
    name: 'Ionitron',
    email: 'ionitron@ionic.io',
    id: '0'
  }
}*/

export const resetPassword = async (email) => {
    try{                
        const response=await fetch(AUTH_SERVER+'/public/resetPassword', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },        
            body: JSON.stringify({email:email})        
        }).then((res) => {
            // Your response to manipulate          
            return res;       
        }).catch((error) => {
            // Your error is here!
            return {msg:'Authentication server not reachable to reset password'+error }        
        });
        const res = await response.json();

        if (res.success) {        
            return res;          
        }else{
            return {msg:'Invalid Call to resetPassword'};
         }
    }catch(e){
        return  {msg:'Authentication server not reachable '+e}      
    }
}