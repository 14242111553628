import React, { Suspense } from 'react';

/* Theme variables */
import './theme/variables.css';
import { DVR_SERVER } from './config';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  IonPage,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { AppContextProvider } from './State';
import urls from './urls';
import Tabs from './Tabs';
import Login  from './pages/Login';
import ResetPassword from './pages/ResetPassword';
//import Signup  from './pages/Signup';
import DVR from './pages/DVR';
import PrivateRoute from './components/PrivateRoute';


const App = () => {
  return (
  <AppContextProvider>
    <IonApp>
      <IonReactRouter>
        <Suspense fallback="loading">
        <IonPage>
          <IonRouterOutlet>
            <Route path={urls.LOGIN} component={Login} exact={true} />
            <Route path={urls.RESET_PASSWORD} component={ResetPassword} exact={true} />
            <Route exact={true} path="/" render={() => <Redirect to={urls.LOGIN} />} />
          </IonRouterOutlet>
          <PrivateRoute component={Tabs} path="/app" />
          {/* <Route path="/app" component={Tabs} /> */}
          <Route path={urls.APP_DVR+"/:streamName"} render={({match}) => (
                <DVR url={`${DVR_SERVER}/${match.params.streamName}/embed.html?dvr=true`} title={match.params.streamName}/>
          )} />
        </IonPage>
        </Suspense>
      </IonReactRouter>
    </IonApp>
  </AppContextProvider>
  );
}

export default App;
