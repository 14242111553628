import React from 'react';
import { 
  /*IonBackButton,
  IonButtons,
  IonButton,*/
  IonHeader, 
  IonPage, 
  IonToolbar, 
  IonTitle, 
  IonContent 
} from '@ionic/react';
import { Link } from 'react-router-dom';
import urls from '../urls';

const DVR = (props) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>      
        
          
          <IonTitle>            
          <Link to={urls.APP_HOME}>X</Link>&nbsp;&nbsp;Grabaciones 7 días {props.title}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
    <IonContent>
       <iframe src={props.url} title={props.title} target="_parent" frameBorder='0' style={{ width: '100%', height: '100%' }} allowFullScreen ></iframe>       
    </IonContent>
     </IonPage>
  )
};

export default DVR;
