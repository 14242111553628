import { useState, useEffect } from "react";
import { AUTH_SERVER } from './config';


export const sleep = (n) => new Promise(r => setTimeout(r, n));

export const decodeJWT = (token) => {
  try{
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join('')); 
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.log('Invalid JWT token');
    return null;
  }
};

//export const img = (path) => `https://ionic-react-demos.s3.amazonaws.com/${path}`;
export const img = (path) => `${AUTH_SERVER}/assets/logos/${path}`;


export const msToTime = (d) => {
  var seconds = Math.floor((d / 1000) % 60),
    minutes = Math.floor((d / (1000 * 60)) % 60);

  return minutes + ":" + (seconds < 10 ? `0${seconds}` : seconds);
}

/* Generic fetch hook with 3 states loading, data (has been get) or error */
export function useFetch(uri, options) {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {    
    let mounted = true;    
    if (!uri) return;  
    console.log("util->useEffect useFetch");
    if (mounted) {  
      fetch(uri, options)
        .then(data => {setLoading(false); return data.json()})      
        .then(setData)
        .then(() => setLoading(false))
        .catch(setError);
    }
    return () => mounted = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri]);

  return {
    loading,
    data,
    error
  };
}
