import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
//import './videojs_themes/VideojsPlayerForest.css';
import './videojs_themes/VideojsPlayerCity.css';
import '../chromecast/wifibytes-chromecast.css';
import '../chromecast/wifibytes-chromecast.js';
import 'videojs-playlist/dist/videojs-playlist.js';
import 'videojs-playlist-ui/dist/videojs-playlist-ui';
//import 'videojs-playlist-ui/';
import 'font-awesome/css/font-awesome.css';
import { img } from '../util';
import './videojs-playlist-ui.css';
//import '../chromecast/example.js';
import { isPlatform } from '@ionic/core';

import { AppContext,
  playStream,
}
from '../State';
import {
  IonIcon, IonFab,IonFabButton,
} from '@ionic/react';
import {
  arrowBack,
  arrowForward,
} from 'ionicons/icons';

//import '@silvermine/videojs-chromecast/dist/silvermine-videojs-chromecast.css';
//require('@silvermine/videojs-chromecast')(videojs,{preloadWebComponents:true});

export default class VideojsPlayer extends React.Component {


  constructor(props) {
    super(props);
    try {
      this.originalPlayList = this.props.playlist;
    }catch (error) {
      this.originalPlayList = [];
    }
    this.state = {isShown: true};
    this.zappingFunction = this.zappingFunction.bind(this);
    this.nextChannel = this.nextChannel.bind(this);
    this.previousChannel = this.previousChannel.bind(this);
    this.setIsShown = this.setIsShown.bind(this);
    this.showTimeout = null;
  }

  zappingFunction(event){
    if(event.keyCode === 39) {
      //Do whatever when arrow right is pressed
      this.nextChannel()
    }
    if(event.keyCode === 37) {
      //Do whatever when arrow left is pressed
      this.previousChannel();
    }
    /* Fullscreen */
    if(event.keyCode === 70) {
      this.player.requestFullscreen();
    }
  }

  setIsShown() {
    this.setState({isShown:true});
    this.showTimeout = setTimeout(() => this.setState({isShown:false}),2500)
  }

  nextChannel(){
    this.player.playlist.next();
    if (!this.player.isFullscreen()) {        //To get EPG updated
        this.context.dispatch(playStream(this.originalPlayList[this.player.playlist.currentIndex()]));
    }
  }
  previousChannel(){
    this.player.playlist.previous();
    if (!this.player.isFullscreen()) {       //To get EPG updated
        this.context.dispatch(playStream(this.originalPlayList[this.player.playlist.currentIndex()]));
    }
  }

  componentDidMount() {
    const playlist = this.props.playlist?this.props.playlist.map(source => {
      let poster = img(source.img)+'.svg';
      if (source.type === 'MOVIES' || source.type === 'SERIES') {
        poster = source.posterURL
      }

      return ({
      sources:[{src:source.url}],
      poster: poster,
      title:source.title,
      name:'.',
      description:source.description,
      thumbnail:img(source.img)+'.svg',
    })}):[];

    // instantiate Video.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      this.wifibytesChromecastPlugin(); //PLUGIN PROPI
      //this.chromecast(); //PLUGIN SILVERLIGHT
    });
    this.player.playlist(playlist);
    //Get clicked video index
    const index = this.player.playlist.indexOf(this.props.sources[0].src);
    //Set current item video from playlist
    this.player.playlist.currentItem(index);
    //PlayList UI
    this.player.playlistUi({horizontal: true});

    let that = this;
    let Button = videojs.getComponent('Button');
    let PrevButton = videojs.extend(Button, {
      constructor: function() {
        Button.apply(this, arguments);
        this.addClass('icon-angle-left');
        this.controlText("Previous");
      },
      handleClick: function() {
        console.log('click previous');
        that.player.playlist.previous();
        //playStream(that.originalPlayList[that.player.playlist.currentIndex()]);
        that.context.dispatch(playStream(that.originalPlayList[that.player.playlist.currentIndex()]));
      }
    });
    let NextButton = videojs.extend(Button, {
      constructor: function() {
        Button.apply(this, arguments);
        this.addClass('icon-angle-right');
        this.controlText("Next");
      },
      handleClick: function() {
        console.log('click next');
        that.player.playlist.next();
        that.context.dispatch(playStream(that.originalPlayList[that.player.playlist.currentIndex()]));
      }
    });
    // Register the new buttons component
    videojs.registerComponent('NextButton', NextButton);
    videojs.registerComponent('PrevButton', PrevButton);
    //this.player.getChild('controlBar').addChild('PrevButton', {}, 0);
    //this.player.getChild('controlBar').addChild('NextButton', {}, 2);
    videojs.getAllPlayers()[0].on('fullscreenchange',()=>{
      // Fix to get EPG updated when transition from fullscreen to no fullscreen
      if (!this.player.isFullscreen()){
        this.context.dispatch(playStream(this.originalPlayList[this.player.playlist.currentIndex()]));
      }
    });

    // PENDENT: fer que full screen quan mobil esta en landscape
    videojs.getAllPlayers()[0].on('playerresize',(el)=>{
      if (isPlatform("mobile") && !this.player.isFullscreen()){
        const heightVideo = this.player.currentDimension('height');
        const heightWindow = window.innerHeight;
        if ((heightVideo*1.2)>heightWindow) {
          this.player.requestFullscreen();
        }
      }
    });

    window.addEventListener("keydown", this.zappingFunction, false);
    this.setIsShown();
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      /*this.player.wifibytesChromecastPlugin().dispose();
      console.log("wifibytesChromecastPlugin().dispose()");*/
      this.player.dispose();
      console.log("VideojsPlayer player.dispose()");
    }
    if (this.showTimeout) clearTimeout(this.showTimeout);
    this.showTimeout = null;
    window.removeEventListener("keydown", this.zappingFunction, false);
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div className="player-container" onMouseEnter={this.setIsShown} onTouchStart={this.setIsShown}>
        <div data-vjs-player className="player-container">
          <video ref={ node => this.videoNode = node } className="video-js vjs-theme-city" id="playerI"></video>
        </div>
        { this.state.isShown && (
          <>
          <IonFab class="player-navigation-buttons" horizontal="end" vertical="top" slot="fixed">
            <IonFabButton color="primary" secondary onClick={this.nextChannel}><IonIcon icon={arrowForward} /></IonFabButton>
          </IonFab>
          <IonFab class="player-navigation-buttons" horizontal="beginning" vertical="top" slot="fixed">
            <IonFabButton color="primary" secondary onClick={this.previousChannel}><IonIcon icon={arrowBack} /></IonFabButton>
          </IonFab> </>)}

        {/* <div className="vjs-playlist"></div>   */}
      </div>
    )
  }
}
VideojsPlayer.contextType = AppContext;
