import videojs from 'video.js';
import { isPlatform } from '@ionic/react';
//import { imgByStreamType } from '../util';
const Plugin = videojs.getPlugin('plugin');
//const SENDER_SDK_URL = 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';

//let cast,chrome; //Fake declarations
class WifibytesChromecastPlugin extends Plugin {

    static url_video;
    static type_video;
    static streamInfo;
    static androidCastSession;

    constructor(player, options) {
        super(player, options);

        // Whenever the player emits a playing or pause event, we update the
        // state if necessary.
        this.remotePlayer = undefined;
        this.remotePlayerController = undefined;

        this.player = player;
        //this.on(player, ['playing', 'pause'], this.updateState);
        this.on('statechanged', this.logState);

        this.on(player,'timeupdate',this.timeUpdated);

        this.id = ""

        WifibytesChromecastPlugin.url_video=player.currentSrc();
        WifibytesChromecastPlugin.type_video=player.currentType();
        WifibytesChromecastPlugin.streamInfo = player.playlist()[player.playlist.currentIndex()];

        this.initButtonChromeCast = this.initButtonChromeCast.bind(this);
        this.initAndroidChromecast = this.initAndroidChromecast.bind(this);

        this.listenerAndroid = function() {
            this.initButtonChromeCast(player,this.initAndroidChromecast);
        }.bind(this);

        if (isPlatform("android")) {
            document.addEventListener("deviceready",this.listenerAndroid);

            //document.addEventListener("deviceready",()=>this.initAndroidChromecast(false));
        }else{ //Web
            //this.initButtonChromeCast(player);
            this.initializeCastPlayer = this.initializeCastPlayer.bind(this);
            this.initializeCastPlayer();
            //loadScript(SENDER_SDK_URL,function(){ setTimeout(this.initializeCastPlayer,5000)}.bind(this));  //Ja està en index.html
        }
    }
    initButtonChromeCast(player,clickFunction=undefined){
        let Button = videojs.getComponent('Button');
        let gcl;
        let buttonChromecast = new Button(player, {
            clickHandler: function(event) {

              videojs.log('Clicked assaasas');
              if (clickFunction) {
                player.pause();
                console.log("playerCurrentTime begore android chromecat = "+player.currentTime())
                clickFunction(player.currentTime());
              }
            }
          });
        if (isPlatform("android")) { //TO RESTORE
            gcl=document.createElement('img')
            gcl.id = 'cast-button-android';
            gcl.src="../assets/images_chromecast/ic_cast_white_24dp.png";
        }else{
            gcl=document.createElement('google-cast-launcher')
            gcl.id = 'cast-button';
        }

        buttonChromecast.el().appendChild(gcl);
        player.controlBar.addChild(buttonChromecast); //TO RESTORE?
        //player.addChild(buttonChromecast);
    }

    dispose() {
        super.dispose();
        videojs.log('the WifibytesChromecastPlugin is being disposed');
        if (isPlatform("android")) {
            document.addEventListener("deviceready", this.listenerAndroid);
        }
    }

    updateState() {
        this.setState({playing: !this.player.paused()});
    }

    logState(changed) {
        videojs.log(`I'm wifibytesChromecastPlugin. Let you know the player is now ${this.state.playing ? 'playing' : 'paused'}`);
    }
    /**
     * Control remotePlayer when we change currentTime video from mobile or web app 
     */
    timeUpdated(){
        if (isPlatform("android")) {
            if (WifibytesChromecastPlugin.androidCastSession) {
                let peticioCanviCurrentTime = new window.chrome.cast.media.SeekRequest();

                peticioCanviCurrentTime.currentTime = this.player.currentTime();
                WifibytesChromecastPlugin.androidCastSession.seek(peticioCanviCurrentTime,function(){
                    console.log("Seek works");
                }
                ,function(error){
                    console.log(error);
                })
            }
        }else{
            if (this.remotePlayer.isConnected) {
                this.remotePlayer.currentTime = this.player.currentTime();
                this.remotePlayerController.seek();
            }
        }
    }

    initAndroidChromecast(currentTime_) {
        // use default app id
        var _session;
        //var appId = window.chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID;
        var appId = '8955A254';
        var apiConfig = new window.chrome.cast.ApiConfig(new window.chrome.cast.SessionRequest(appId), function sessionListener (session) {
                // The session listener is only called under the following conditions:
                // * will be called shortly chrome.cast.initialize is run
                // * if the device is already connected to a cast session
                // Basically, this is what allows you to re-use the same cast session 
                // across different pages and after app restarts
            }, function receiverListener (receiverAvailable) {
                // receiverAvailable is a boolean.
                // True = at least one chromecast device is available
                // False = No chromecast devices available
                // You can use this to determine if you want to show your chromecast icon
                //let buttonChromecast = document.getElementById('cast-button-android');
                if (receiverAvailable){

                    //buttonChromecast.src="../assets/images_chromecast/ic_cast_connected_white_24dp.png";
                    console.log("receiverAvailable = TRUE")
                    //this.initButtonChromeCast(this.player,this.initAndroidChromecast);//NEW
                    //if (!popup) return;
                }else{
                    console.log("receiverAvailable = FALSE")
                }
            });

        //alert("click android1");
        // initialize chromecast, this must be done before using other chromecast features
        window.chrome.cast.initialize(apiConfig, function () {
            // Initialize complete
            // Let's start casting
            //alert("click android2"); 
            requestSession(currentTime_);
        }, function (err) {
            // Initialize failure
        });

        function requestSession (currentTime_) {
            // This will open a native dialog that will let 
            // the user choose a chromecast to connect to
            // (Or will let you disconnect if you are already connected)
            console.log("requestSession");
            window.chrome.cast.requestSession(function (session) {
                // Got a session!
                _session = session;

                // Load a video
                loadMedia(currentTime_);
            }, function (err) {
                // Failed, or if err is cancel, the dialog closed
                let buttonChromecast = document.getElementById('cast-button-android');
                buttonChromecast.src="../assets/images_chromecast/ic_cast_white_24dp.png";
            });
        }
        function loadMedia (currentTime_) {
            //let videoUrl = this.player.currentSrc();
            let videoUrl = WifibytesChromecastPlugin.url_video;
            //let mediaInfo =  new window.chrome.cast.media.MediaInfo(videoUrl, this.player.currentType());
            let mediaInfo =  new window.chrome.cast.media.MediaInfo(videoUrl, WifibytesChromecastPlugin.type_video);
            console.log("loadMedia");
            mediaInfo.metadata = new window.chrome.cast.media.GenericMediaMetadata();
            mediaInfo.metadata.metadataType = window.chrome.cast.media.MetadataType.GENERIC;
            mediaInfo.metadata.title = WifibytesChromecastPlugin.streamInfo.title;
            mediaInfo.metadata.subtitle = WifibytesChromecastPlugin.streamInfo.description;
            mediaInfo.metadata.images = [{'url': WifibytesChromecastPlugin.streamInfo.poster}];

            _session.loadMedia(new window.chrome.cast.media.LoadRequest(mediaInfo), function (media) {
                // You should see the video playing now!
                // Got media!
                let buttonChromecast = document.getElementById('cast-button-android');
                buttonChromecast.src="../assets/images_chromecast/ic_cast_connected_blue_24dp.png";

                let peticioCanviCurrentTime = new window.chrome.cast.media.SeekRequest();
                WifibytesChromecastPlugin.androidCastSession = media;
                //if (player){
                    console.log("player.currentTime "+currentTime_);
                    peticioCanviCurrentTime.currentTime = currentTime_;
                    media.seek(peticioCanviCurrentTime,function(){
                        console.log("Seek works");
                    }
                    ,function(error){
                        console.log(error);
                    })
                /*}else{
                    console.log("NO PLAYER INSTANCE");
                }*/
                //media.currentTime = media;
            }, function (err) {
                // Failed (check that the video works in your browser)
            });
        }
    }
    // Web Chrome initialization
    initializeCastPlayer() {
        //console.log("INITIALIZE CAST PLAYER")
        try{
            var options = {};
            // Set the receiver application ID to your own (created in the
            // Google Cast Developer Console), or optionally
            // use the chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID
            options.receiverApplicationId = '8955A254';
            //options.receiverApplicationId = window.chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID;

            // Auto join policy can be one of the following three:
            // ORIGIN_SCOPED - Auto connect from same appId and page origin
            // TAB_AND_ORIGIN_SCOPED - Auto connect from same appId, page origin, and tab
            // PAGE_SCOPED - No auto connect
            options.autoJoinPolicy = window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED;

            this.castInstance = window.cast.framework.CastContext.getInstance();
            //window.cast.framework.CastContext.getInstance().setOptions(options);
            this.castInstance.setOptions(options);

            this.remotePlayer = new window.cast.framework.RemotePlayer();

            this.remotePlayerController = new window.cast.framework.RemotePlayerController(this.remotePlayer);

            /*this.remotePlayerController.addEventListener(
                window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,

                () => {console.log("CURRENT_TIME_CHANGED switchPlayer");}
            );*/
            this.remotePlayerController.addEventListener(
                window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
                () => {console.log("CALL switchPlayer");this.switchPlayer()}
            );

            let castState = this.castInstance.getCastState();
            if (castState !== 'NO_DEVICES_AVAILABLE'){
                this.initButtonChromeCast(this.player);
            }

        }catch(error){
            console.log(error+" Chromecast is only supported in chrome browser");
        }
    }
    switchPlayer() {
        //debugger
        if(!this.player) return;
        else{
            if (window.cast && window.cast.framework) {
                console.log("SWITCH PLAYER");
                if (this.remotePlayer.isConnected) {

                    let castSession = this.castInstance.getCurrentSession();
                    //OJO
                    //let stateCast=window.cast.framework.CastContext.getInstance().getCastState();
                    this.remotePlayerController.playOrPause();

                    let src_ = this.player.currentSrc() || WifibytesChromecastPlugin.url_video;
                    let type_= this.player.currentType() || WifibytesChromecastPlugin.type_video;
                    let currentTime_ = this.player.currentTime();

                    let mediaInfo = new window.chrome.cast.media.MediaInfo(
                        src_,type_);

                    mediaInfo.metadata = new window.chrome.cast.media.GenericMediaMetadata();
                    mediaInfo.metadata.metadataType = window.chrome.cast.media.MetadataType.GENERIC;
                    mediaInfo.metadata.title = WifibytesChromecastPlugin.streamInfo.title;
                    mediaInfo.metadata.subtitle = WifibytesChromecastPlugin.streamInfo.description;
                    mediaInfo.metadata.images = [{'url': WifibytesChromecastPlugin.streamInfo.poster}];

                    if (this.remotePlayer.isPaused) {
                        this.remotePlayerController.playOrPause();
                    }
                    let request = new window.chrome.cast.media.LoadRequest(mediaInfo);

                    console.log("Before castSession "+castSession);
                    let that = this;
                    if (castSession){
                        console.log("castSession ready ");
                        castSession.loadMedia(request).then(
                            //this.playerHandler.loaded.bind(this.playerHandler),
                            function(){
                                console.log("Arre gat");
                                //request.currentTime = currentTime_;

                                //request.currentTime = currentTime_;
                                that.remotePlayer.currentTime = currentTime_;
                                that.remotePlayerController.seek();
                            },
                            function (errorCode) {
                                //this.playerState = PLAYER_STATE.ERROR;
                                console.log('Remote media load error: ' +errorCode);
                            });
                    }

                    return;
                }
            }
        }
    }//End switchPlayer
}


/*function loadScript(src, callback)
{
  var s,
      r,
      t;
  r = false;
  s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = src;
  s.onload = s.onreadystatechange = function() {
     console.log("readyState -> "+ this.readyState ); //uncomment this line to see which ready states are called.
    if ( !r && (!this.readyState || this.readyState === 'complete') )
    {
        r = true;
        window['__onGCastApiAvailable'] = function(isAvailable) {

            if (isAvailable) {
                console.log("CAST api aavilable __onGCastApiAvailable");
                callback();
            }else{
                console.log(`CAST api NOT avilable.
                window['__onGCastApiAvailable'] is only trigged you sender app (your webpage) is hosted with https. If you are trying this under http it will silently fail and chrome.cast will be undefined.`);
            }
        };
    }else{
        window['__onGCastApiAvailable'] = function(isAvailable) {

            if (isAvailable) {
                console.log("ELSE CAST api aavilable __onGCastApiAvailable");
                callback();
            }else{
                console.log("ELSE CAST api NOT avilable")
            }
        }
    }
  };
  t = document.getElementsByTagName('script')[0];
  t.parentNode.insertBefore(s, t);
}*/

WifibytesChromecastPlugin.VERSIOM = '1.0';
videojs.registerPlugin('wifibytesChromecastPlugin',WifibytesChromecastPlugin);