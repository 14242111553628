import React, { useCallback, useContext, useState } from 'react';

import {
  IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
  IonList, IonListHeader, IonItem, IonThumbnail, IonLabel,
  IonButtons, IonButton, IonIcon, IonPopover
} from '@ionic/react';

import { useTranslation } from 'react-i18next';

import { ellipsisVertical, removeCircleOutline } from 'ionicons/icons';

import { AppContext,
        getRecentStreams,
        getFavStreams,
        playStream,
        favStream,
        logout,
        getUserEmail,
        setPlayList,
      }
      from '../State';

import { img } from '../util';

import urls from '../urls';

import './You.css';

import { ISP_NAME } from '../config'

const You = ({ history }) => {
  const { state, dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  const [showUserMenuEvent, setShowUserMenuEvent] = useState(null);
  const recentStreams = getRecentStreams(state);
  const favStreams = getFavStreams(state);

  //REVISAR
  /*const doPlay = useCallback(stream => {
    dispatch(playStream(stream));
  },[dispatch]);*/

  const doPlay = useCallback((stream,playList) => {
    dispatch(playStream(stream));
    dispatch(setPlayList(playList));
  },[dispatch]);

  const doLogout = useCallback(async () => {
    setShowUserMenuEvent(null);
    dispatch(logout());
    history.push(urls.LOGIN);
  }, [dispatch, history]);

  return (
    <IonPage className="you-page">
      <IonHeader className="gradient-background">
        <IonToolbar className="display-block">
          <IonButtons style={{padding:'20px'}}>
            <img className="logo" style={{marginRight:'20px'}} src={"../assets/logo-"+ISP_NAME+".svg"} height="40px" alt="logo" />
            <img className="logoSmall" style={{marginRight:'20px'}} src={"../assets/logo-"+ISP_NAME+".svg"} height="30px" alt="logo" />
            <IonButton className="menu">{t('you.your_channels')}</IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton fill="clear" onClick={e => { e.persist(); setShowUserMenuEvent(e) }}>
              <IonIcon icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div class="light-background height-100">
          <IonPopover
              event={showUserMenuEvent}
              isOpen={!!showUserMenuEvent}
              onDidDismiss={() => setShowUserMenuEvent(null)}>
            <IonContent>
              <IonList>
                <IonItem onClick={e => { e.preventDefault(); doLogout()}} detail={true} href="">
                  <IonLabel>{t('you.logout')}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>{getUserEmail(state)}</IonLabel>
                </IonItem>
              </IonList>
            </IonContent>
          </IonPopover>
          { favStreams.length > 0 &&
            <IonList>
              <IonListHeader>
                <IonLabel className="title-text">{t('you.favourite_channels')}</IonLabel>
              </IonListHeader>
              {favStreams.map(stream => (
                <IonItem key={stream.title} onClick={() => doPlay(stream,favStreams)} button>
                  <IonThumbnail slot="start">
                      {stream.type === 'MOVIES' &&
                      <img src={stream.posterURL} alt={stream.title}/>
                      }
                      {stream.type !== 'MOVIES' &&
                      <img src={img(stream.type==='DVR'?stream.parent:stream.img)+'.svg'} alt={stream.title}/>
                      }
                  </IonThumbnail>
                  <IonLabel>
                    <h2>{stream.title}</h2>
                    <p>{stream.artist}</p>
                  </IonLabel>
                  <IonIcon
                    onClick={e => { e.stopPropagation(); dispatch(favStream(stream))}}
                    icon={removeCircleOutline} slot="end" />
                </IonItem>
              ))}
            </IonList>
          }
          { recentStreams.length > 0 &&
            <IonList>
              <IonListHeader>
                <IonLabel className="title-text">{t('you.recent_channels')}</IonLabel>
              </IonListHeader>
              {recentStreams.map(stream => (
                <IonItem key={stream.title} onClick={() => doPlay(stream,recentStreams)} button>
                  <IonThumbnail slot="start" className="channel-badge">
                      {(stream.type === 'MOVIES' || stream.type === 'SERIES') &&
                        <img src={stream.posterURL} alt={stream.title}/>
                      }
                      {(stream.type !== 'MOVIES' && stream.type !== 'SERIES') &&
                        <img src={img(stream.img)+'.svg'} alt={stream.title}/>
                      }
                  </IonThumbnail>
                  <IonLabel className="channel-info">
                    <h2>{stream.title}</h2>
                    <p>{stream.description}</p>
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default You;
