import React, { useContext, useRef, useState, useEffect } from 'react';
import {
	IonButton,
	IonContent,
	IonPage,
	IonList,
	IonItem,
	//IonLabel,
	IonInput,
	IonAlert,
	IonLoading,
	IonRow,
	IonGrid,
	IonCol,
} from '@ionic/react';

import { AppContext,loggedIn } from '../State';
import { AUTH_SERVER } from '../config';
import { useTranslation } from 'react-i18next';
import { login } from '../auth';
import urls from '../urls';
import { img, useFetch } from '../util';
import './Form.css';
import './Login.css';
import { isPlatform } from '@ionic/core';
//import { Redirect } from 'react-router-dom';
import {ISP_NAME, ISP_WEB} from '../config'

const Login = ({ history }) => {
	const { dispatch } = useContext(AppContext);
	const { t } = useTranslation();
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ , setFormErrors ] = useState(null);
	const [ showLoading, setShowLoading ] = useState(false);
	const [ showAlert, setShowAlert] = useState(false);
	const formRef = useRef(null);

	/**
	 * We call our auth server API to know if our Token is valid (Not has expired)
	 * If there is a JWT Token in localStorage and is valid we do autologin
	 * */
	const { loading, data, error } = useFetch(
		`${AUTH_SERVER}/public/validate`,
		{
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({token:localStorage.getItem('token')})
		}
	);

	/**
	*  If there is token in localStorage and has been validated we do AUTOLOGIN
	*  When 'data' changes due to previous 'fetch', we call this useEffect
	* */
	useEffect(() => {
		if (data && data.isvalid) {
			dispatch(loggedIn(data.user));
			history.replace(urls.APP_HOME);
		}
	}, [data,dispatch,history])

	const goTo = path => {
		history.push(path, { direction: 'forward' });
	}

	const handleSubmit = async e => {
		e.preventDefault();

		try {
			setShowLoading(true);
			const user = await login(email, password);
			if (user.token) {
				localStorage.setItem('token', user.token);
				dispatch(loggedIn(user.user));
				history.replace(urls.APP_HOME);
				setShowLoading(false);
			}else{
				//dispatch(logout(user));
				setShowLoading(false);
				setShowAlert({open:true,message:user.msg});
				setFormErrors("Bad credentials");
			}
		} catch (e) {
			console.error(e);
			setShowLoading(false);
			setFormErrors(e);
		}
	}

	if (loading && !error) return <IonLoading isOpen={loading} message={t('login.autologin')} />;

	return (
	<IonPage className="gradient-background form">
		<IonContent>
			<IonAlert
					isOpen={showAlert.open}
					onDidDismiss={() => setShowAlert(false)}
					cssClass='my-custom-class'
					header={'Error'}
					subHeader={t('login.failedLogin')}
					message={showAlert.message}
					buttons={['OK']}
			/>
			<IonLoading isOpen={showLoading} message={t('login.loggingIn')} onDidDismiss={() => setShowLoading(false)}/>
			<div className='wrapper'>
				<div className='d-flex-center'>
					<IonGrid>
						<IonRow justify-content >
							<IonCol style={{textAlign:'center'}}><img src={"../assets/logo-"+ISP_NAME+".svg"} className="login-logo" alt="Agotelecom webpage" /></IonCol>
						</IonRow>
					</IonGrid>
					<form onSubmit={handleSubmit} method="post" ref={formRef} action="" className="form-box light-background">
						<IonList>
							<IonItem>
								{/* <IonLabel position={'fixed'}>Email</IonLabel> */}
								<IonInput type="email" placeholder="Email" required value={email} onInput={e => setEmail(e.currentTarget.value)} />
							</IonItem>
							<IonItem>
								{/* <IonLabel position={'fixed'}>Password</IonLabel> */}
								<IonInput
									type="password"
									placeholder="Password"
									value={password}
									required
									onInput={e => setPassword(e.currentTarget.value)}
									/>
							</IonItem>
							<input type="submit" className="submit-enter" />
							<IonButton expand="block" type="submit">{t('login.loggingIn')}</IonButton>
						</IonList>
					</form>
					<div className="below-form">
						{/*<a className="create" href="#/" onClick={(e) => { e.preventDefault(); goTo('/signup')}}>Create account instead</a> */}
						<a href="#/" onClick={(e) => { e.preventDefault(); goTo('/reset-password')}}>{t('login.passForgotten')}</a>
					</div>
				</div>
			</div>
		</IonContent>
	</IonPage>
	);
};

export default Login;
