import React, { useCallback, useContext, useRef, useState } from 'react';
import {
  IonContent, IonHeader, IonItem, IonLabel,
  IonPage, IonSearchbar, IonThumbnail, IonToolbar,
  useIonViewDidEnter, IonButtons, IonButton
} from '@ionic/react';

import { AppContext, playStream, setPlayList } from '../State';
import { useTranslation } from 'react-i18next';
import { search } from '../search';

import './Search.css';

import { img } from '../util';

import { ISP_NAME } from '../config'

const Search = () => {
  const { state, dispatch } = useContext(AppContext);
  //const [ isSearching, setIsSearching ] = useState(false);
  const [ streams, setStreams ] = useState([]);
  const searchbarRef = useRef();
  const { t } = useTranslation();
  const doSearch = useCallback(async (e) => {
    const q = e.target.value;

    if (!q) {
      setStreams([]);
      return;
    }
    setStreams(await search(q, state));
  },[state]);

  const doPlay = useCallback(stream => {
    dispatch(playStream(stream));
    dispatch(setPlayList([stream]));
  }, [dispatch]);

  // Use this pattern to focus a search box whenever the
  // page enters from a navigation event
  useIonViewDidEnter(() => {
    // searchbarRef.current.setFocus();
  })

  return (
    <IonPage className="search-page">
      <IonHeader className="gradient-background">
        <IonToolbar className="display-block">
          <IonButtons style={{padding:'20px'}}>
            <img className="logo" style={{marginRight:'20px'}} src={"../assets/logo-"+ISP_NAME+".svg"} height="40px" alt="logo" />
            <img className="logoSmall" style={{marginRight:'20px'}} src={"../assets/logo-"+ISP_NAME+".svg"} height="30px" alt="logo" />
            <IonButton className="menu">{t('search.search')}</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar className="light-background" style={{padding:'0px 14px'}}>
          <IonSearchbar ref={searchbarRef} onIonChange={doSearch} />
        </IonToolbar>
      </IonHeader>
      <IonContent id="main-content">
        <div className="light-background height-100">
          <div className="item-list">
            {streams.map(stream => (
              <IonItem key={stream.title} onClick={() => doPlay(stream)} button>
                <IonThumbnail slot="start" className="channel-badge">
                  {stream.type === 'MOVIES' &&
                  <img src={stream.posterURL} alt={stream.title}/>
                  }
                  {stream.type !== 'MOVIES' &&
                  <img src={img(stream.img)+'.svg'} alt={stream.title}/>
                  }
                </IonThumbnail>
                <IonLabel className="channel-info">
                  <h2><b>{stream.title}</b></h2>
                  <p>{stream.description}</p>
                </IonLabel>
              </IonItem>
            ))}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Search;