import React, {useState,useEffect} from 'react';
//import epgData from '../data/epg.json';
import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
} from '@ionic/react';
import { AUTH_SERVER, TIMESHIFT_EPG } from '../config';
import './Epg.css';
import EpgList from './EpgList.jsx';
import { useTranslation } from 'react-i18next';
import { isPlatform } from '@ionic/react';

const Epg = (props) =>{
  const [isFetching,setFetching] = useState(true);
  const [epgEvents,setEpgEvents] = useState(null);
  const { t } = useTranslation();
  const [activeSegment,setActiveSegment] = useState( t('epg.today'));
  // Similar to componentDidMount and componentDidUpdate
  useEffect(() => {
      let isSubscribed = true;
      // Returns YESTERDAY, TODAY , TOMORROW or 'DAY_NAME dd/mm/yyyy' if doesn't fit in previous classes
      function readableDate(inputDate){
        let today = new Date();
        //Today and input dates normalized to same time 00:00:00
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0, 0);
        inputDate.setHours(0);
        inputDate.setMinutes(0);
        inputDate.setSeconds(0, 0);
        let weekday = new Array(7);
        weekday[0] = t('epg.sunday');
        weekday[1] = t('epg.monday');
        weekday[2] = t('epg.tuesday');
        weekday[3] = t('epg.wednesday');
        weekday[4] = t('epg.thursday');
        weekday[5] = t('epg.friday');
        weekday[6] = t('epg.saturday');
        const diffMilliseconds = today.getTime() - inputDate.getTime();

        if (diffMilliseconds === 0)
          return t('epg.today')
        else if (diffMilliseconds === 86400000)
          return t('epg.yesterday')
        else if (diffMilliseconds === -86400000)
          return t('epg.tomorrow')
        else {
          //if (isPlatform("android") || isPlatform("ios")) {
            return weekday[inputDate.getDay()] + ' ' + inputDate.getDate() + '/' + (inputDate.getMonth() + 1)
          //}else {
            //return weekday[inputDate.getDay()] + ' ' + inputDate.getDate() + '/' + (inputDate.getMonth() + 1)
            //return weekday[inputDate.getDay()] + ' ' + inputDate.toLocaleDateString("es-ES",{})
          //}
        }
      }
      // Used to group epg events by day of the week
      function prepareAndConquer(aPrograms,epgFormat='flussonic'){
        let daysGroups = new Map();
        let newPrograms = []
        //We iterate over all EPG events to assigns to which day fits everyone of them
        if (epgFormat === 'flussonic') {
          newPrograms = aPrograms.map((pItem) => {
            let d=new Date(0);
            d.setUTCSeconds(pItem.spa.start);

            if (!daysGroups.has(readableDate(new Date(d.getTime()) )))
              daysGroups.set(readableDate(new Date(d.getTime())),[]);
              return (
              {...pItem,
                title: pItem.spa.name,
                start: pItem.spa.start,
                duration: pItem.spa.duration,
                description: pItem.spa.ext && pItem.spa.ext.text,
                textDate:d.toLocaleDateString("es-ES",{}),
                textTime:d.toLocaleTimeString(),
                epgSource: epgFormat,
                dayOfweek:readableDate(new Date(d.getTime()))
              }
            )
          });
        // Epg format is feched from middleware (more reliable source)
        }else {
          newPrograms = aPrograms.map((pItem) => {

            if (!daysGroups.has(readableDate(new Date(pItem.date) )))
              daysGroups.set(readableDate(new Date(pItem.date)),[]);

              let duration = (parseInt(pItem.endEPOC) - parseInt(pItem.startEPOC))/1000; //In seconds
              /**
                Ugly BUG : When an EPG event transit between two different days duration was negative 
                because we only have start time (Ex 23:30) and end time (1:35) and end time usally refers next day
                One day 86400 seconds (3600 * 24)
                Ex: Film start 23:30 wednesday and finish 1:30 thursday
              */
              if (duration < 0) duration = 86400 + duration;

              return (
              {...pItem,
                title: pItem.titleProgram,
                start: (parseInt(pItem.startEPOC)/1000) + TIMESHIFT_EPG, //In seconds
                duration: duration, //In seconds
                description: pItem.description,
                textTime: pItem.startTimeStr,
                epgSource: epgFormat,
                dayOfweek:readableDate(new Date(pItem.date)),
              }
            )
          });
        }
        // iterate over map keys and add all EPG events that falls into that group
        for (let dow of daysGroups.keys()) {
          daysGroups.set(dow,newPrograms.filter(i => i.dayOfweek === dow))
        }
        return daysGroups;
      }
      if (isSubscribed) setFetching(true);

      let uri=`${AUTH_SERVER}/private/stream/getEpg?streamName=${props.stream.type==='DVR'?props.stream.parent:props.stream.name}`;
      // Means we get EPG programs from other more reliable source than flussonic
      if (props.stream.epgId) {
        let now=new Date();
        // Five days ago from current date
        let startDate = new Date((new Date()).setDate(now.getDate() - 5));
        // Two days in future from current date
        let endDate = new Date((new Date()).setDate(now.getDate() + 2));
        uri=`${AUTH_SERVER}/private/epg/getStreamEpgByIdAndDate?epgId=${props.stream.epgId}&dateQueryStart=${startDate.toISOString().split('T')[0]}&dateQueryEnd=${endDate.toISOString().split('T')[0]}`
      }
      const options={
        method: 'get',
        headers: {
          'Accept': 'application/json, text/plain',
          'Content-Type': 'application/json',
          'authorization': 'Bearer '+localStorage.getItem('token')
        },
      };
      fetch(uri, options)
        .then(response =>
          response.json()
        )
        .then(data => {
          try{
            // Data comes from EPG middleware (more reliable if we have EPG programs)
            if (props.stream.epgId) {
              let events = data.data;
              if (isSubscribed) {
                setEpgEvents(prepareAndConquer(events,'MIDDLEWARE'));
                setFetching(false);
              }
            // Data comes from flussonic EPG information
            }else{
              let {events} = JSON.parse(data.body);
              if (isSubscribed) {
                setEpgEvents(prepareAndConquer(Object.values(events)));
                setFetching(false);
              }
            }
          }catch(error){
            setEpgEvents(null);
            setFetching(false);
          }
          //this.setState({...this.state,name:name,title:title, events: prepareAndConquer(Object.values(events)),isFetching:false});
        })
        .catch(error => console.log(error));

        return () => (isSubscribed = false);
  },[props.stream.name]);

  if (isFetching || !epgEvents){
    return (
      <ion-text className="ion-float-right" style={{padding:'10px'}} color="medium" className="noEPG">{t('epg.not_available')} {props.stream.name}</ion-text>
    )
  }else{

    return (
        <>
        <IonToolbar slot="fixed" className='ago-background'>
        <IonSegment scrollable color="primary" key={props.stream.name} value={activeSegment}  onIonChange={e => setActiveSegment(e.detail.value)} >
        {
        [...epgEvents.keys()].map((keyByDay,index) =>
          (<IonSegmentButton key={keyByDay+props.stream.name} value={keyByDay}>
            <IonLabel>{keyByDay}</IonLabel>
          </IonSegmentButton>)
        )
        }
        </IonSegment>
        </IonToolbar>
        {
        [...epgEvents.keys()].map((keyByDay,index) =>
          (<EpgList key={index} activeSegment={activeSegment} dayOfWeek={keyByDay} stream={props.stream} events={epgEvents.get(keyByDay)} />)
        )
        }
        </>
    )
  }
}

export default Epg;