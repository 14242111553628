import React from 'react';
import { 
  /*IonBackButton, 
  IonButtons, */
  IonGrid,
  IonCol,
  IonRow,
  IonHeader, 
  IonPage, 
  IonToolbar, 
  IonTitle, 
  IonContent 
} from '@ionic/react';
import { useTranslation } from 'react-i18next';

const Readme = () => {
  const { t } = useTranslation();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('readme.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h1>{t('readme.text.title')}</h1>

        <p>
          <b>
            <p>{t('readme.text.subtitle1')}</p> 
            <p>{t('readme.text.subtitle2')}</p>
            <p>{t('readme.text.subtitle3')}</p>
          </b>
        </p>
        <p> {t('readme.text.description')}
        
        </p>
        <footer><strong>{t('readme.text.footer.title')}</strong>
        <p>{t('readme.text.footer.street')}</p>
        <p>{t('readme.text.footer.address')}</p>
        </footer>
        <IonGrid>
      <IonRow justify-content >
        <IonCol style={{textAlign:'center'}}><a href="https://ivace.es"><img src={'../assets/FEDER+Ivace+Declaracion-CS.png'} alt="Subvención IVACE" /></a></IonCol>
      </IonRow>
      </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Readme;
