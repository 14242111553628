import React, {useEffect, useContext} from 'react';
import { AppContext} from './State';
/* Theme variables */
import './theme/variables.css';
import './Tabs.css';
//import { useTranslation } from 'react-i18next';
import { Redirect, Route } from 'react-router-dom';
import {
  IonIcon,
  //IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react';

import {
  home,
  informationCircle,
  person,
  search
} from 'ionicons/icons';

import Home  from './pages/Home';
import Search from './pages/Search';
import You    from './pages/You';
import Track  from './pages/Track';
import Readme from './pages/Readme';

import PrivateRoute from './components/PrivateRoute';

//import TabBarSticky from './components/TabBarSticky';
//import TrackPreview from './components/TrackPreview';

const Tabs = () => {
  const { state } = useContext(AppContext);
  //const { t } = useTranslation();
  useEffect(() => {
    state.theme === "dark"
      ? document.body.classList.add("dark")
      : document.body.classList.remove("dark");
  });
  return (
    <>
    {/*<TabBarSticky>
      <TrackPreview />
    </TabBarSticky> */}
    <IonTabs>
      <IonRouterOutlet>
        <PrivateRoute path="/app/browse" component={Home} exact={true} />
        <PrivateRoute path="/app/track/:trackId" component={Track} />
        <PrivateRoute path="/app/search" component={Search} exact={true} />
        <PrivateRoute path="/app/search/track/:trackId" component={Track} />
        <PrivateRoute path="/app/account" component={You} />
        <PrivateRoute path="/app/readme" component={Readme} />
        <PrivateRoute path="/app/" render={() => <Redirect to="/app/browse" />} exact={true} />
      </IonRouterOutlet>

      <IonTabBar slot="bottom">
        <IonTabButton tab="browse" href="/app/browse">
          <IonIcon icon={home} />
          {/* <IonLabel>{t('tabs.home').toUpperCase()}</IonLabel> */}
        </IonTabButton>
        <IonTabButton tab="search" href="/app/search">
          <IonIcon icon={search} />
          {/* <IonLabel>{t('tabs.search').toUpperCase()}</IonLabel> */}
        </IonTabButton>
        <IonTabButton tab="account" href="/app/account">
          <IonIcon icon={person} />
          {/* <IonLabel>{t('tabs.yourList').toUpperCase()}</IonLabel> */}
        </IonTabButton>
        <IonTabButton tab="readme" href="/app/readme">
          <IonIcon icon={informationCircle} />
          {/* <IonLabel>{t('tabs.readme').toUpperCase()}</IonLabel> */}
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  </>
  );
}

export default Tabs;
