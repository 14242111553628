import React,{useContext,useCallback} from 'react';
import { img } from '../util';
import {
    IonLabel,
    IonRow,
    IonCol,
    IonSlides,
    IonSlide,
    IonImg,
    IonBadge,
  } from '@ionic/react';
import { AppContext,
    playStream,
    setPlayList,
  }from '../State';

import './RowStreamsSlides.css';

const RowStreamsSlides = (props) => {
    const { dispatch } = useContext(AppContext);

    const doPlay = useCallback((stream,playList) => {
        dispatch(playStream(stream));
        dispatch(setPlayList(playList));
    },[dispatch]);

    const slideOpts = {
        slidesPerView: 'auto',
        zoom: false,
        grabCursor: true,
        virtual: true,
    };

    return (

    <IonRow key={'row_'+props.id} style={{maxHeight:'260px'}}>
      <IonLabel color="light" className="my-label">{props.rowTitle}</IonLabel>
       {/* BUG https://github.com/ionic-team/ionic-framework/issues/18784 props.streams.length added very important */}
      <IonSlides key={props.streams.length + '_slides_'+props.id} options={slideOpts}>
        {props.streams.filter(value => value.type === props.streamTypeTab).map(stream => (
        <IonSlide key={'slide_'+props.id+"_"+stream.id} className={stream.type}>
          <IonCol class="ion-col"
            key={'col_'+stream.id+"_"+props.id}
            onClick={() => doPlay(stream,props.streams.filter(value => value.type === props.streamTypeTab))}>
            <div className={'image-preview' + stream.type}> 
              { (stream.type === 'SERIES') &&
              <IonBadge className="serieBadge">S{stream.season} E{stream.episode}</IonBadge>
              }
              { (stream.type !== 'TV') &&
                <IonImg className="image-previewCCTV card-image" src={stream.type==='CCTV'?img(stream.img)+'.svg':stream.posterURL} className={stream.type==='CCTV'?"card-image":"card-imageMovie"} />
              }
              {(stream.type === 'TV') &&
              <IonImg src={img(stream.img)+'.svg'} className="card-image"/>
              }
              { (stream.type === 'CCTV') &&
              <IonBadge className="cctvBadge">{stream.title}</IonBadge>
              }
            </div> 
          </IonCol>
        </IonSlide>
        ))}
      </IonSlides>
    </IonRow>
)
}
export default RowStreamsSlides;