import React,{ useContext, useCallback, useEffect } from 'react';
import {
    IonButton,
  } from '@ionic/react';
import './Promotion.css';
import { useTranslation } from 'react-i18next';
import { AppContext,
    playStream,
    setPlayList,
    getStreamByName
  }from '../../State';


const Promotion = (props) =>{
  const { state,dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  const doPlay = useCallback((streamName) => {
    const stream = getStreamByName(state,streamName);
    dispatch(playStream(stream[0]));
    dispatch(setPlayList(stream));
   },[dispatch]);

  return (
    <>
    <div class="promotion" >
        <div class="content">
            {props.type === 'MOVIES' &&
                <><h1>{t("moviePromotion.title")}</h1>
                <p className="description">{t("moviePromotion.description")}</p></>
            }
            <IonButton onClick={() => doPlay(t("moviePromotion.streamName"))}slot="bottom">{t("moviePromotion.play")}</IonButton>
        </div>
        {props.type === 'MOVIES' &&
            <img  className="bg" src={t("moviePromotion.background")} alt={t("moviePromotion.title")}></img>
        }
  </div></>
  )
}

export default Promotion;