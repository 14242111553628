import React from 'react';
import { decodeJWT } from './util';
/**
 * This is a simple redux-like state management pattern for React using hooks
 * that might be useful in your simpler Ionic React apps that don't
 * require something as complex as Redux.
 * 
 * See each page for an example of how to read from state and
 * dispatch actions.
 * 
 * Learn more:
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 */

export const AppContext = React.createContext();

const reducer = (state, action) => {
  const playing = getPlaying(state);
  const ct = getCurrentStream(state);
  console.log ("EXECUTING REDUCER "+action.type);
  switch (action.type) {
    case 'SET_PLAYER_OPEN': {
      return {
        ...state,
        ui: {
          ...state.ui,
          playerOpen: action.open
        }
      }
    }
    case 'PAUSE': {
      return {
        ...state,
        playing: {
          ...playing,
          paused: true
        }
      }
    }
    case 'PLAY': {          
        if (action.stream) {
          const index = getStreamIndex(state, action.stream.name);  
          const streamName_ = action.stream.name;
          const newRecentStreams = getRecentStreams(state).filter(t => t.name !== action.stream.name);
          let recentStreams_ = [...newRecentStreams];          
          if (action.stream.type !== 'DVR') {
            recentStreams_ = [action.stream, ...newRecentStreams];
            recentStreams_ = recentStreams_.slice(0,11); //Only last 10 items viewed
          }
          try {
            localStorage.setItem('recents:'+state.auth.user.email,JSON.stringify(recentStreams_));
          }catch(error) {
            console.log("Can't write LocalStorage "+error)
          }

        return {
          ...state,
          ui: {
            playerOpen: true
          },
          user: {
            ...state.user,
            recentStreams: [...recentStreams_],
          },          
          playing: {
            ...playing,
            index,         
            streamName:streamName_,   
            progress: 0,
            paused: false
          }
        }
      }
      return {
        ...state,
        playing: {
          ...playing,
          paused: false
        }
      }
    }
    case 'SEEK': {
      return {
        ...state,
        playing: {
          ...playing,
          progress: action.time <= ct.time ? Math.floor(action.time) : ct.time
        }
      }
    }
    case 'NEXT': {
      return {
        ...state,
        playing: {
          index: (playing.index + 1) % getStreams(state).length,
          progress: 0
        }
      }
    }
    case 'PREV': {
      return {
        ...state,
        playing: {
          index: Math.max(0, state.playing.index - 1),
          progress: 0
        }
      }
    }
    case 'FAV': {
      debugger
      const isFav = isFavStream(state, action.stream);
      //const newFavs = getFavStreams(state).filter(t => t.id !== action.stream.id);
      const newFavs = getFavStreams(state).filter(t => t.name !== action.stream.name);
      localStorage.setItem('favs:'+state.auth.user.email,!isFav ? JSON.stringify([ct, ...newFavs]) : JSON.stringify(newFavs));
      return {
        ...state,
        user: {
          ...state.user,
          favStreams: !isFav ? [ct, ...newFavs] : newFavs
        }
      }
    }
    case 'LOGOUT': {
      localStorage.removeItem('token');    
      return {
        ...state,
        playing: null,
        auth: {
          ...state.auth,
          user: null
        } 
      }
    }
    case 'LOGGED_IN': {
      let favStreams_ = [...state.user.favStreams];
      let recentStreams_ = [...state.user.recentStreams];
      /* If there is something stored in LocalStorage we want it as initial state */      
      if (localStorage.getItem('recents:'+action.user.email)) {
        recentStreams_ = JSON.parse(localStorage.getItem('recents:'+action.user.email));        
      }
      if (localStorage.getItem('favs:'+action.user.email)) {
        favStreams_ = JSON.parse(localStorage.getItem('favs:'+action.user.email));        
      }      

      return {
        ...state,
        user:{
          ...state.user,
          favStreams:favStreams_,
          recentStreams:recentStreams_
        },
        auth: {
          ...state.auth,
          user: action.user
        }
      }
    }
    case 'ADD_STREAM': {
      const index = getStreamIndex(state, action.stream.name);
      if (index < 0 ) {
        action.stream.id = state.player.streams.length;
        return {
          ...state,
          player: {
            ...state.player,
            streams:[...state.player.streams,action.stream]
          }
        }
      }
      return { ...state }
    }
    case 'SET_STREAMS': {      
      return {
        ...state,
        player: {
          ...state.player,
          streams:[...action.streams]
        }
      }
    }

    case 'SET_PLAYLIST': {           
      return {
        ...state,
        player: {
          ...state.player,
          playlist:[...action.playListStreams]
        }
      }    
    }

    case 'SET_STREAM_TYPE_TAB': {
      
      return {
        ...state,
        user: {
          ...state.user,
          streamTypeTab: action.streamTypeTab
        }
      }
    }
    default:
      return state;
  }
  
};

const logger = (reducer) => {
  const reducerWithLogger = (state, action) => {
    console.log("%cPrevious State:", "color: #9E9E9E; font-weight: 700;", state);
    console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
    const reducerInstance = reducer(state,action);
    console.log("%cNext State:", "color: #47B04B; font-weight: 700;", reducerInstance);
    return reducerInstance;
  };

  return reducerWithLogger;
}

const loggerReducer = logger(reducer);

const initialState = {
  playing: {
    index: 0,
    streamName:'',    
  },
  theme:'light',
  auth: {
    user: null,
    //server:'https://i.wifibytes.com',
    //server:'http://localhost:4443',
  },
  user: {
    recentStreams: [], 
    favStreams: [],
    streamTypeTab:'TV', //Possible TV, CCTV, MOVIES, SERIES 
  },
  ui: {
    playerOpen: false
  },
  player: {    
    streams:[      
    ],
    hotStreams:[],
    newStreams:[],
    playlist:[]  
  },
};

export function AppContextProvider(props) {
  const fullInitialState = {
    ...initialState,
  }
  let [state, dispatch] = React.useReducer(loggerReducer, fullInitialState);//DEBUG
  //let [state, dispatch] = React.useReducer(reducer, fullInitialState);//PRODUCTION
  let value = { state, dispatch };
  
  return (
    <AppContext.Provider value={value}>
      {props.children}
    </AppContext.Provider>
  );
}

export const AppContextConsumer = AppContext.Consumer;

// Some state action creators
export const openPlayer = () => ({
  type: 'SET_PLAYER_OPEN',
  open: true
})

export const closePlayer = () => ({
  type: 'SET_PLAYER_OPEN',
  open: false
})

export const pauseStream = () => ({
  type: 'PAUSE'
});

export const playStream = (stream) => ({ 
    type: 'PLAY', 
    stream  
});

export const seekStream = (time) => ({
  type: 'SEEK',
  time
});

export const nextStream = () => ({
  type: 'NEXT',
});

export const prevStream = () => ({
  type: 'PREV',
});

export const favStream = (stream) => ({
  type: 'FAV',
  stream
});


export const addStream = (stream) => {    
  return ({
    type:'ADD_STREAM',
    stream
  })
};

export const setStreams = (streams) => {    
  return ({
    type:'SET_STREAMS',
    streams
  })
};

export const setPlayList = (playListStreams) => {     
  return ({
    type:'SET_PLAYLIST',
    playListStreams
  })
};

export const setStreamTypeTab = (streamTypeTab) => {
  return ({
    type:'SET_STREAM_TYPE_TAB',
    streamTypeTab
  })
};

export const logout = () => ({
  type: 'LOGOUT'
});

export const loggedIn = (user) => ({
  type: 'LOGGED_IN',
  user
});

// Some state selectors
export const isPlayerOpen = (state) => state.ui.playerOpen;
// Get auth server url
//export const getAuthServer = (state) => state.auth.server;
// Get all streams in database
export const getStreams = (state) => state.player.streams;
export const getNewStreams = (state) => 
  state.player.streams.filter(t => state.player.newStreams.find(nt => nt === t.id));
export const getHotStreams = (state) => 
  state.player.streams.filter(t => state.player.hotStreams.find(nt => nt === t.id));

export const getFavStreams = (state) => state.user.favStreams;
export const getRecentStreams = (state) => state.user.recentStreams;
export const getStreamTypeTab = (state) => state.user.streamTypeTab;
/*export const isFavStream = (state, stream) => {  
  return !!state.user.favStreams.find(t => t.id === stream.id);
}*/

export const isFavStream = (state, stream) => {    
  return !!state.user.favStreams.find(t => t.name === stream.name);
}
export const getPlaying = (state) => state.playing;

export const getCurrentStream = (state, index) => state.player.streams[state.playing ? state.playing.index : -1];
export const getCurrentPlaylist = (state) => state.player.playlist ;

//export const getStream = (state, id) => state.player.streams.find(t => t.id === id);
//export const getStream = (state, name) => state.player.streams.find(t => t.name === name);
//export  const getStreamIndex = (state, id) => state.player.streams.findIndex(t => t.id === id);
export const getStreamIndex = (state, name) => state.player.streams.findIndex(t => t.name === name);
export const getStreamByName = (state,name) => state.player.streams.filter(s => s.name === name);
//export const getUser = (state) => state.user;
export const getUserEmail = (state) => {
  try{
    return state.auth.user.email;
  } catch (error){
    const token = localStorage.getItem('token');
    //decodeJWT(localStorage.getItem('token'));
    const userPayload = decodeJWT(token);
    if (userPayload) {
      loggedIn(userPayload);
      return userPayload.email;
    } else {
      return null;
    }
  }
}
